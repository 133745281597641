export type KaipassSchema = {
  id: number
  created_at: string
  updated_at: string
  name: string
  code: string
  groups: {
    title: string
    fields: string[]
  }[]
  schemas: {
    name: string
    type: string
    label: string
    isRequired: boolean
    options?: {
      name: string
      code: string
    }[]
  }[]
}

export type ValuesKaipass = {
  title: string
  content: string
  image: string
  route: string
  btn_name: string
  url: string
  utm_source: string
  utm_medium: string
  utm_campaign: string
}

export const KAIPASS_SCHEMA: KaipassSchema = {
  id: 4,
  created_at: '2021-04-06T01:56:16.472958',
  updated_at: '2021-04-06T15:32:13.744847',
  name: 'Kaipass Notification',
  code: 'kaipass',
  groups: [
    {
      title: 'Action',
      fields: ['route', 'btnName', 'url', 'utm_source', 'utm_medium', 'utm_campaign'],
    },
  ],
  schemas: [
    {
      name: 'title',
      type: 'string',
      label: 'Title',
      isRequired: true,
    },
    {
      name: 'content',
      type: 'text_area',
      label: 'Content',
      isRequired: true,
    },
    {
      name: 'image',
      type: 'image',
      label: 'Image',
      isRequired: true,
    },
    {
      name: 'route',
      type: 'select',
      label: 'Route',
      isRequired: true,
      options: [
        { name: 'News', code: 'news' },
        { name: 'Brand Detail', code: 'brand-detail' },
        { name: 'Reward Detail', code: 'reward-detail' },
        { name: 'Web', code: 'web' },
      ],
    },
    {
      name: 'btn_name',
      type: 'string',
      label: 'Button Name',
      isRequired: true,
    },
    {
      name: 'url',
      type: 'string',
      label: 'Link',
      isRequired: true,
    },
    {
      name: 'utm_source',
      type: 'string',
      label: 'UTM Source',
      isRequired: true,
    },
    {
      name: 'utm_medium',
      type: 'string',
      label: 'UTM Medium',
      isRequired: true,
    },
    {
      name: 'utm_campaign',
      type: 'string',
      label: 'UTM Campaign',
      isRequired: true,
    },
  ],
}

export const INIT_VALUES: ValuesKaipass = {
  title: 'string',
  content:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ',
  image: '',
  route: 'news',
  btn_name: 'string',
  url: 'string',
  utm_source: 'string',
  utm_medium: 'string',
  utm_campaign: 'string',
}

export const test = {
  type: 'entrance',
  subtype: 'entrance',
  nodeValue: {
    channel: null,
  },
  nodeAttribute: true,
  alias: '1',
  id: '4112c85b-f0fd-4afb-8bae-21cb827b7ff8',
  childrenNodes: [
    {
      type: 'condition',
      subtype: 'checkAudienceSegment',
      nodeValue: {
        audience_segment_id: 433,
      },
      nodeAttribute: true,
      alias: '2',
      id: '180e51c4-3d81-47bd-913f-5d4dac00ac31',
      childrenNodes: [
        {
          type: 'waitIn',
          subtype: 'waitIn',
          nodeValue: {},
          nodeAttribute: true,
          alias: '3',
          id: 'eef1bb6d-0be3-4154-bb9b-9db70b1d1b99',
          childrenNodes: [
            {
              type: 'aiCheck',
              subtype: 'aiCheck',
              nodeValue: {},
              nodeAttribute: false,
              alias: '4',
              id: 'ca7f4b7d-e308-4490-aec9-780201a80ec5',
              childrenNodes: [
                {
                  type: 'channel',
                  subtype: 'ol_app',
                  nodeValue: {
                    utm_source: 'testCJ',
                    utm_medium: 'testCJ',
                    utm_campaign: 'testCJ',
                    type: 'generate',
                  },
                  nodeAttribute: true,
                  alias: '5',
                  id: 'e6099978-4251-44ff-9b93-85bb1e58729c',
                  childrenNodes: [
                    {
                      type: 'channel',
                      subtype: 'appInPage',
                      nodeValue: {},
                      nodeAttribute: true,
                      alias: '6',
                      id: '77f158ca-4fdf-4645-8437-757f968b64d3',
                      childrenNodes: [
                        {
                          type: 'virtual',
                          subtype: 'virtual',
                          nodeAttribute: true,
                          alias: '7',
                          id: '807c215a-261d-4e03-916f-f4571f63e44f',
                          childrenNodes: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              type: 'condition',
              subtype: 'checkUserAttr',
              nodeValue: {},
              nodeAttribute: true,
              alias: '7',
              id: 'f297e33f-f3ac-455f-a776-2e3fc47f446d',
              childrenNodes: [
                {
                  type: 'condition',
                  subtype: 'checkUserAttr',
                  nodeValue: {},
                  nodeAttribute: false,
                  alias: '8',
                  id: 'afc8ce14-8c47-4fb3-a0f5-0f7337dbf361',
                  childrenNodes: [
                    {
                      type: 'condition',
                      subtype: 'checkUserAttr',
                      nodeValue: {},
                      nodeAttribute: false,
                      alias: '9',
                      id: 'd189e8e1-f44c-467d-a6fd-c5db72591fe0',
                      childrenNodes: [
                        {
                          type: 'channel',
                          subtype: 'ol_app',
                          nodeValue: {},
                          nodeAttribute: false,
                          alias: '10',
                          id: '8b1bafce-082f-49aa-9036-c023d0292a59',
                          childrenNodes: [
                            {
                              type: 'virtual',
                              subtype: 'virtual',
                              nodeAttribute: true,
                              alias: '11',
                              id: '1fd4d185-6e3d-4498-9e69-3073483a4515',
                              childrenNodes: [],
                            },
                          ],
                        },
                        {
                          type: 'aiCheck',
                          subtype: 'aiCheck',
                          nodeValue: {},
                          nodeAttribute: true,
                          alias: '11',
                          id: '35562ced-8441-43b9-ba09-4d0033dd25de',
                          childrenNodes: [
                            {
                              type: 'channel',
                              subtype: 'ol_app',
                              nodeValue: {},
                              nodeAttribute: true,
                              alias: '12',
                              id: 'ecfdad71-826c-4dc8-8b13-83936a115661',
                              childrenNodes: [
                                {
                                  type: 'channel',
                                  subtype: 'webInPage',
                                  nodeValue: {},
                                  nodeAttribute: true,
                                  alias: '13',
                                  id: '55ce8887-8d06-4511-b4df-70454911a76d',
                                  childrenNodes: [
                                    {
                                      type: 'waitIn',
                                      subtype: 'waitIn',
                                      nodeValue: {},
                                      nodeAttribute: true,
                                      alias: '14',
                                      id: '3b4cc511-331c-4531-98da-964006e8674a',
                                      childrenNodes: [
                                        {
                                          type: 'channel',
                                          subtype: 'ol_app',
                                          nodeValue: {},
                                          nodeAttribute: false,
                                          alias: '15',
                                          id: 'b4c1e567-4431-4480-8718-75feb696b0a1',
                                          childrenNodes: [
                                            {
                                              type: 'virtual',
                                              subtype: 'virtual',
                                              nodeAttribute: true,
                                              alias: '16',
                                              id: '67def317-c316-414b-b594-60e1a30db911',
                                              childrenNodes: [],
                                            },
                                          ],
                                        },
                                        {
                                          type: 'channel',
                                          subtype: 'ol_app',
                                          nodeValue: {},
                                          nodeAttribute: true,
                                          alias: '16',
                                          id: '58a87563-14e6-4047-8a63-b4066f33d0d6',
                                          childrenNodes: [
                                            {
                                              type: 'virtual',
                                              subtype: 'virtual',
                                              nodeAttribute: true,
                                              alias: '17',
                                              id: '02063308-2c13-4ad4-b7e5-e82177a2e8f3',
                                              childrenNodes: [],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: 'condition',
                      subtype: 'checkUserAttr',
                      nodeValue: {},
                      nodeAttribute: true,
                      alias: '17',
                      id: 'b1e20f54-ef61-4d77-a302-67639c7ac4e7',
                      childrenNodes: [
                        {
                          type: 'channel',
                          subtype: 'ol_app',
                          nodeValue: {},
                          nodeAttribute: false,
                          alias: '18',
                          id: 'b370aa35-6447-4ee6-98f0-00627fd7f032',
                          childrenNodes: [
                            {
                              type: 'virtual',
                              subtype: 'virtual',
                              nodeAttribute: true,
                              alias: '19',
                              id: 'e438d1cd-f788-4ac4-8950-5c59682be078',
                              childrenNodes: [],
                            },
                          ],
                        },
                        {
                          type: 'aiCheck',
                          subtype: 'aiCheck',
                          nodeValue: {},
                          nodeAttribute: true,
                          alias: '19',
                          id: '802d4556-c228-4e97-8456-829428c15b9f',
                          childrenNodes: [
                            {
                              type: 'channel',
                              subtype: 'ol_app',
                              nodeValue: {
                                utm_source: 'testCJ',
                                utm_medium: 'testCJ',
                                utm_campaign: 'testCJ',
                                type: 'generate',
                              },
                              nodeAttribute: true,
                              alias: '20',
                              id: '29d4b8c9-eeb2-49be-bd9c-e57ef2338bb0',
                              childrenNodes: [
                                {
                                  type: 'virtual',
                                  subtype: 'virtual',
                                  nodeAttribute: true,
                                  alias: '21',
                                  id: 'e910ad57-cfd7-4d87-b71b-55e26b4e5709',
                                  childrenNodes: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  type: 'waitIn',
                  subtype: 'waitIn',
                  nodeValue: {},
                  nodeAttribute: true,
                  alias: '21',
                  id: '1d4e0f3f-7a4d-482c-a7f9-ab8deecdc4d4',
                  childrenNodes: [
                    {
                      type: 'aiCheck',
                      subtype: 'aiCheck',
                      nodeValue: {},
                      nodeAttribute: false,
                      alias: '22',
                      id: 'e6ea264e-b7cf-40a3-b32d-9a6da0b7f20e',
                      childrenNodes: [
                        {
                          type: 'channel',
                          subtype: 'appInPage',
                          nodeValue: {},
                          nodeAttribute: true,
                          alias: '23',
                          id: '96376d67-a0c2-4e51-9cf8-46da2d570df8',
                          childrenNodes: [
                            {
                              type: 'channel',
                              subtype: 'znsByTemplateId',
                              nodeValue: {},
                              nodeAttribute: true,
                              alias: '24',
                              id: 'ac4ccce3-490c-4ef3-8155-0193ceb10dee',
                              childrenNodes: [
                                {
                                  type: 'channel',
                                  subtype: 'ol_app',
                                  nodeValue: {},
                                  nodeAttribute: true,
                                  alias: '25',
                                  id: '22bf1af0-6967-4fb5-8cc3-71fd747a218a',
                                  childrenNodes: [
                                    {
                                      type: 'virtual',
                                      subtype: 'virtual',
                                      nodeAttribute: true,
                                      alias: '26',
                                      id: '5c56920c-34ee-404e-b4e4-f838f7d54112',
                                      childrenNodes: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: 'channel',
                      subtype: 'ol_app',
                      nodeValue: {},
                      nodeAttribute: true,
                      alias: '26',
                      id: '519c0ef9-5bc1-43a6-b5dc-78433c2a319a',
                      childrenNodes: [
                        {
                          type: 'wait',
                          subtype: 'wait',
                          nodeValue: {
                            wait_time: 5,
                            time_format: 'min',
                          },
                          nodeAttribute: true,
                          alias: '27',
                          id: '80ab1045-4f1b-44a8-b4b0-7fa5c567d81c',
                          childrenNodes: [
                            {
                              type: 'aiCheck',
                              subtype: 'aiCheck',
                              nodeValue: {},
                              nodeAttribute: true,
                              alias: '28',
                              id: 'db7d46cb-f0c3-48ba-863a-8420fbd7895c',
                              childrenNodes: [
                                {
                                  type: 'channel',
                                  subtype: 'ol_app',
                                  nodeValue: {},
                                  nodeAttribute: true,
                                  alias: '29',
                                  id: '1b05f603-ea9c-4bcb-aa35-d3a3f1e9b565',
                                  childrenNodes: [
                                    {
                                      type: 'channel',
                                      subtype: 'appInPage',
                                      nodeValue: {},
                                      nodeAttribute: true,
                                      alias: '30',
                                      id: '0cb81d12-978b-4986-a364-144f932ecac8',
                                      childrenNodes: [
                                        {
                                          type: 'channel',
                                          subtype: 'sms',
                                          nodeValue: {},
                                          nodeAttribute: true,
                                          alias: '31',
                                          id: '34eb52a6-1a3b-4f90-90d8-723266f08d9c',
                                          childrenNodes: [
                                            {
                                              type: 'virtual',
                                              subtype: 'virtual',
                                              nodeAttribute: true,
                                              alias: '32',
                                              id: '3852df46-997b-4462-8683-9b0fe4c1c188',
                                              childrenNodes: [],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
}

export function findNodesByTypeFromEntrance(
  node: any,
  targetNodeId: any,
  types: any,
  path: any = [],
  result: any = []
) {
  // Add the current node to the path
  path.push(node)

  // If we reached the target node, check for matching types along the path
  if (node.nodeUuid === targetNodeId) {
    const matchingNodes = path.filter((n) => types.includes(n.nodeType))
    result.push(...matchingNodes)
    return result
  }

  // Recursively search in the children nodes if they exist
  if (node.childrenNodes && node.childrenNodes.length > 0) {
    for (let child of node.childrenNodes) {
      findNodesByTypeFromEntrance(child, targetNodeId, types, path, result)
    }
  }

  // Remove the current node from the path before going back up the recursion stack
  path.pop()

  return result
}
