export const TIME_VALUE_OPTIONS = [
  {
    name: 'Minutes',
    code: 'min',
  },
  {
    name: 'Hours',
    code: 'hour',
  },
  {
    name: 'Days',
    code: 'day',
  },
]

export const APP_SCREENS_OPTIONS = [
  {
    name: 'App Home',
    code: 'APP_HOME',
  },
  {
    name: 'App Search Result',
    code: 'APP_SEARCH_RESULT',
  },
  {
    name: 'App Product Detail',
    code: 'APP_PRODUCT_DETAIL',
  },
  {
    name: 'App Product Listing',
    code: 'APP_PRODUCT_LISTING',
  },
  {
    name: 'App Cart',
    code: 'APP_CART',
  },
  {
    name: 'App Topup Card',
    code: 'APP_TOPUP_CARD',
  },
  {
    name: 'App Reward Listing',
    code: 'APP_REWARD_LISTING',
  },
  {
    name: 'App Tab Card',
    code: 'APP_TAB_CARD',
  },
]

export const WEB_SCREENS_OPTIONS = [
  {
    name: 'Web Home',
    code: 'WEB_HOME',
  },
  {
    name: 'Web Search Result',
    code: 'WEB_SEARCH_RESULT',
  },
  {
    name: 'Web Product Detail',
    code: 'WEB_PRODUCT_DETAIL',
  },
  {
    name: 'Web Product Listing',
    code: 'WEB_PRODUCT_LISTING',
  },
  {
    name: 'Web Cart',
    code: 'WEB_CART',
  },
  {
    name: 'Web Topup Card',
    code: 'WEB_TOPUP_CARD',
  },
  {
    name: 'Web Reward Listing',
    code: 'WEB_REWARD_LISTING',
  },
  {
    name: 'Web Tab Card',
    code: 'WEB_TAB_CARD',
  },
]

export const APP_IN_PAGE_SCHEMAS = [
  { name: 'title', type: 'dynamic_content', label: 'Title', placeholder: 'Enter Title' },
  {
    name: 'content',
    type: 'dynamic_content',
    label: 'Description',
    placeholder: 'Enter Description',
  },
  {
    name: 'imageUrl',
    type: 'image',
    label: 'Image',
  },
  {
    name: 'ctaTitle',
    type: 'dynamic_content',
    label: 'Button label',
    placeholder: 'Enter Button label',
    row: 1,
  },
  // {
  //   name: 'ctaRedirectLink',
  //   type: 'string',
  //   label: 'CTA Redirect Link',
  // },
  {
    name: 'ctaRedirectDeepLink',
    type: 'dynamic_content',
    label: 'Navigate to',
    placeholder: 'http://bit.ly/abc',
    row: 1,
  },

  {
    name: 'screens',
    type: 'multi_select',
    label: 'Screens',
    options: APP_SCREENS_OPTIONS,
    placeholder: 'Select Screens',
  },
  {
    name: 'timeValue',
    type: 'string',
    label: 'End time',
    placeholder: 'Enter Time Value',
    inputType: 'number',
  },
  {
    name: 'timeFormat',
    type: 'select',
    label: ' ',
    options: TIME_VALUE_OPTIONS,
    placeholder: 'Select Time Format',
  },
  {
    name: 'isStopNextCondition',
    type: 'checkbox',
    label: ' ',
    valueTitle: 'Next Condition',
    description: 'The end time will be based on whichever time comes first',
  },

  {
    name: 'utmSource',
    type: 'string',
    label: 'UTM Source',
  },
  {
    name: 'utmMedium',
    type: 'string',
    label: 'UTM Medium',
  },
  {
    name: 'utmCampaign',
    type: 'string',
    label: 'UTM Campaign',
  },
]
