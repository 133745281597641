import React, { useState, useEffect } from 'react'
import { Modal, Radio, Tooltip } from 'antd'
import { connect } from 'react-redux'
import type { RadioChangeEvent } from 'antd'
import Header from 'Components/CJHeader'
import CreativeContent from 'Containers/CreativeContent'
import Preview from 'Components/CreativeContentsPreview'
import { CurrentType } from 'CustomerJourneyModule'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { addDynamicParams, findNodePosition, formatData, splitValues } from '../helpers'
import './index.scss'
import DynamicParams from 'Containers/Campaign/MainFlow/Components/Body/Content/Form/DynamicParams'
import personalize from 'Assets/images/personalized.png'
import { CJModalPopup } from 'Components/CJNode'
import {
  appStaticPopupSchema,
  appStaticInitialValues,
  appStaticSchemas,
  appDynamicPopupSchema,
  appDynamicInitialValues,
  appDynamicSchemas,
  getStaticInitialValues,
  TABS,
  DEFAULT_FIELDS,
  mergeObjects,
} from './constants'
import axios from 'Utils/cj-axios'
import { useKeyboardShortcut } from 'Containers/Campaign/MainFlow/Components/Body/Content/useKeyboardShortcut'
import { findNodesByTypeFromEntrance } from '../AppPush/constants'
interface Props {
  isPopoverVisible: boolean
  value: any
  initData: any
  current: CurrentType
  isRunning: boolean
  setCurrent: React.Dispatch<React.SetStateAction<CurrentType>>
  setIsPopoverVisible: React.Dispatch<React.SetStateAction<boolean>>
  onAddNewNode: (type: string, values: any) => void
  onUpdateNode: (values: any) => void
  setCreativeContentsState: React.Dispatch<React.SetStateAction<any>>
  parentNode?: any
}

const AppPopup: React.FC<Props> = (props) => {
  const {
    isPopoverVisible,
    value,
    initData,
    setIsPopoverVisible,
    onAddNewNode,
    onUpdateNode,
    setCreativeContentsState,
  } = props
  const { setPreviewData } = useStoreActions((action: any) => action.campaign.creativeContents)
  const [popupProps, setPopupProps] = useState<{
    type: string
    yupSchema: any
    initialValues: any
    schema: any
  }>({
    type: 'ANIMATION',
    yupSchema: appDynamicPopupSchema,
    initialValues: appDynamicInitialValues,
    schema: appDynamicSchemas,
  })
  const [activeTab, setActiveTab] = useState({
    key: 'content',
    label: 'Content',
    fields: DEFAULT_FIELDS,
  })
  const [interactions, setInteractions] = useState<any>([])
  const [segments, setSegments] = useState<any>([])
  const [dynamicFields, setDynamicFields] = useState<any>([])
  const { CJTree } = useStoreState((states) => states.customerJourney)

  useEffect(() => {
    setDynamicFields(
      addDynamicParams(
        activeTab?.fields,
        formatData(
          findNodesByTypeFromEntrance(
            CJTree,
            (props?.current?.nodeUuid as any) ?? props?.parentNode?.childrenNodes?.[0]?.nodeUuid,
            ['waitIn', 'aiCheck', 'condition', 'checkUserAttr']
          )
            ?.map((item: any) => {
              return {
                value: item?.value,
                alias: findNodePosition(CJTree, item?.nodeUuid),
                nodeType: item?.nodeType,
                attributeCode: item?.value?.interaction,
              }
            })
            ?.map((i: any) => {
              if (i?.nodeType === 'waitIn') {
                const typesToMatch = i?.value?.conditions
                  ?.flatMap((condition) => condition.dimension.segments)
                  ?.map((item) => item.type)
                return {
                  ...i,
                  value: interactions?.filter((item: any) => typesToMatch?.includes(item?.code)),
                }
              } else {
                const typesToMatch = i?.value?.segments?.map((item) => item.type)
                return {
                  ...i,
                  value: segments?.filter((item) => typesToMatch?.includes(item?.code)),
                }
              }
            })
        )?.concat(
          [
            'condition',
            'waitIn',
            'wait',
            'split',
            'promotion',
            'coupon',
            'outOfPromotion',
          ]?.includes(props?.parentNode?.nodeType)
            ? []
            : findNodesByTypeFromEntrance(
                CJTree,
                (props?.current?.nodeUuid as any) ||
                  props?.parentNode?.childrenNodes?.[0]?.nodeUuid,
                ['aiCheck']
              )?.flatMap((obj) => {
                return obj?.value?.params?.map((param) => ({
                  ...param,
                  parameter_code: `{{${obj?.alias}--${param?.parameter_code}}}`,
                }))
              })
        )
      )
    )
  }, [
    CJTree,
    props?.current?.nodeUuid,
    interactions,
    segments,
    activeTab?.fields,
    isPopoverVisible,
  ])

  useEffect(() => {
    const fetchInteractions = async () => {
      const resp = await axios.get('/customer-journeys/interactions')
      setInteractions(resp.data)
    }

    const fetchSegments = async () => {
      const resp = await axios.get('/customer-journeys/attributes')
      setSegments(resp.data)
    }

    fetchInteractions()
    fetchSegments()
  }, [])

  const onChange = (e: RadioChangeEvent) => {
    if (e.target.value === 'MODAL') {
      setPopupProps({
        type: 'MODAL',
        yupSchema: appStaticPopupSchema,
        initialValues: appStaticInitialValues,
        schema: appStaticSchemas,
      })
    } else {
      setPopupProps({
        type: 'ANIMATION',
        yupSchema: appDynamicPopupSchema,
        initialValues: appDynamicInitialValues,
        schema: appDynamicSchemas,
      })
    }
  }

  const onTabChange = (key: string) => {
    console.log(key)
  }
  // const { headline, promo_title, promo_content, go_back_button_label, go_back_button_url } =
  //   initData
  const CJPopupModal = React.useContext(CJModalPopup)

  const onCloseModal = () => {
    // setIsModalVisible(false)
    CJPopupModal.modalVisibleOff()
  }

  const onSubmit = async (values: any) => {
    // post field values and HTML DOM for BE usage
    const mergedObject = { ...values }

    for (const key in content) {
      if (content.hasOwnProperty(key)) {
        mergedObject[key] = content[key]
      }
    }
    if (popupProps?.type === 'MODAL') {
      onAddNewNode &&
        (await onAddNewNode(
          'appPopup',
          getStaticInitialValues(
            {
              ...mergeObjects(mergedObject, appStaticInitialValues),
              type: popupProps?.type,
            },
            appStaticInitialValues
          )
        ))
      onUpdateNode &&
        (await onUpdateNode(
          getStaticInitialValues(
            {
              ...mergeObjects(mergedObject, appStaticInitialValues),
              type: popupProps?.type,
            },
            appStaticInitialValues
          )
        ))
    } else {
      onAddNewNode &&
        (await onAddNewNode('appPopup', {
          ...mergeObjects(mergedObject, appDynamicInitialValues),
          type: popupProps?.type,
        }))
      onUpdateNode &&
        (await onUpdateNode({
          ...mergeObjects(mergedObject, appDynamicInitialValues),
          type: popupProps?.type,
        }))
    }
    CJPopupModal.modalVisibleOff()
  }
  useEffect(() => {
    setCreativeContentsState({
      key: 'previewData',
      value: { ...value, ...popupProps?.initialValues } || popupProps?.initialValues,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (!value) {
      setState({ key: 'content', value: {} })
    } else {
      setState({ key: 'content', value: value })
      setPopupProps({
        type: value?.type || 'ANIMATION',
        yupSchema: value?.type === 'MODAL' ? appStaticPopupSchema : appDynamicPopupSchema,
        initialValues:
          value?.type === 'MODAL'
            ? { ...value, ...appStaticInitialValues }
            : { ...value, ...appDynamicInitialValues },
        schema: value?.type === 'MODAL' ? appStaticSchemas : appDynamicSchemas,
      })
    }
  }, [value, CJPopupModal?.isModalVisible])
  useEffect(() => {
    if (value && popupProps?.type === value?.type) {
      setCreativeContentsState({
        key: 'previewData',
        value: { ...value, ...popupProps?.initialValues } || popupProps?.initialValues,
      })
    } else {
      setCreativeContentsState({
        key: 'previewData',
        value: popupProps?.initialValues,
      })
    }
  }, [value, popupProps?.type])
  useEffect(() => {
    setIsPopoverVisible(false)
    // setIsModalVisible(true)
    CJPopupModal.modalVisibleOn()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPopoverVisible])

  const { toggleDynamicParam, content } = useStoreState((state: any) => state.campaignModule)
  const { setState } = useStoreActions((actions: any) => actions.campaignModule)

  useKeyboardShortcut({
    keyCombo: 'Meta+j' || 'Ctrl+j',
    onKeyPressed: () => setState({ key: 'toggleDynamicParam', value: !toggleDynamicParam }),
  })
  return (
    <Modal
      {...props}
      onOk={onCloseModal}
      onCancel={() => CJPopupModal.modalVisibleOff()}
      visible={CJPopupModal?.isModalVisible}
      maskClosable
      title={<Header {...props} />}
      footer={[]}
      width={1200}
      destroyOnClose
      closable={false}
      className="ChannelsModal"
    >
      <div className={`PopupComponent flex gap-4 relative ${props.isRunning ? 'is-running' : ''}`}>
        <div className="PopupInputs flex-1">
          <Radio.Group onChange={onChange} value={popupProps?.type} className="mb-4">
            <Radio value={'ANIMATION'}>Dynamic</Radio>
            <Radio value={'MODAL'}>Static</Radio>
          </Radio.Group>
          {popupProps?.type === 'MODAL' ? (
            <CreativeContent
              onSubmit={onSubmit}
              submitText="Save"
              initValues={mergeObjects(value, popupProps?.initialValues)}
              validationSchema={popupProps?.yupSchema}
              fields={popupProps?.schema}
              onChange={(name: string, value: string) => {
                setPreviewData({
                  key: name,
                  value: value,
                })
                setState({ key: 'content', value: { ...content, [name]: value } })
              }}
            />
          ) : (
            <>
              <div className=" grid grid-cols-3 text-center mb-4">
                {TABS?.map((tab) => {
                  return (
                    <div
                      onClick={() => setActiveTab(tab)}
                      key={tab?.key}
                      className=" font-semibold pb-2 border-b cursor-pointer"
                      style={{ borderColor: activeTab?.key === tab?.key ? '#1890FF' : '#00000026' }}
                    >
                      {tab?.label}
                    </div>
                  )
                })}
              </div>
              <CreativeContent
                onSubmit={onSubmit}
                submitText="Save"
                initValues={mergeObjects(value, popupProps?.initialValues)}
                validationSchema={popupProps?.yupSchema}
                fields={dynamicFields}
                onChange={(name: string, value: string) => {
                  setPreviewData({
                    key: name,
                    value: value,
                  })
                  setState({ key: 'content', value: { ...content, [name]: value } })
                }}
              />
              {/* <Tabs.TabPane tab="Styles" key="styles">
                  <CreativeContent
                    onSubmit={onSubmit}
                    submitText="Save"
                    initValues={
                      getSelectedInitialValue(value, styleFields) ||
                      getSelectedInitialValue(popupProps?.initialValues, styleFields)
                    }
                    validationSchema={getSelectedSchemaFields(popupProps?.yupSchema, styleFields)}
                    fields={filterSchemasByFields(popupProps?.schema, styleFields)}
                  />
                </Tabs.TabPane> */}
            </>
          )}
        </div>
        {popupProps?.type === 'MODAL' ? null : (
          <div className="flex-1">
            <Preview isPreview={true} type={'app_popup'}></Preview>
          </div>
        )}
        {toggleDynamicParam && (
          <div
            className=" bg-white border rounded-lg transition-all duration-500"
            style={{ width: '400px' }}
          >
            <DynamicParams
              extraParams={[
                {
                  group_code: 'ai_params',
                  group_name: 'AI Params',
                  parameters: splitValues(
                    dynamicFields?.find((item: any) => item?.type === 'dynamic_content')
                      ?.dynamicParams
                  )?.aiValues?.map((i: any) => {
                    return {
                      parameter_code: i?.parameter_code?.match(/\{\{(.+?)\}\}/)?.[1],
                      format: i?.format?.map((item: any) => {
                        return {
                          ...item,
                          format_key: item?.format_key,
                          format_value: item?.format_value,
                        }
                      }),
                    }
                  }),
                },
                {
                  group_code: 'dimension_params',
                  group_name: 'Dimension Params',
                  parameters: splitValues(
                    dynamicFields?.find((item: any) => item?.type === 'dynamic_content')
                      ?.dynamicParams
                  )?.otherValues?.map((i: any) => {
                    return {
                      parameter_code: i?.parameter_code?.match(/\{\{(.+?)\}\}/)?.[1],
                      format: i?.format?.map((item: any) => {
                        return {
                          ...item,
                          format_key: item?.format_key,
                          format_value: item?.format_value,
                        }
                      }),
                    }
                  }),
                },
                {
                  group_code: 'entrance_params',
                  group_name: 'Entrance Params',
                  parameters: findNodesByTypeFromEntrance(
                    CJTree,
                    (props?.current?.nodeUuid as any) ||
                      props?.parentNode?.childrenNodes?.[0]?.nodeUuid,
                    ['entrance']
                  )?.[0]?.value?.params?.map((item: any) => {
                    return {
                      ...item,
                      parameter_code: `1--${item?.parameter_code}`,
                    }
                  }),
                },
              ]}
            />
          </div>
        )}
        {!toggleDynamicParam && (
          <Tooltip
            title={
              <div className="flex gap-1 flex-col w-max">
                <div className=" font-semibold text-sm">Personalize</div>
                <div className=" text-sm">Add personalized parameters to your message</div>
                <div className=" text-sm text-gray-300">Cmd + J (Mac) / Ctrl + J (Window)</div>
              </div>
            }
            overlayStyle={{ width: 'max-content' }}
            overlayInnerStyle={{ width: 'max-content' }}
            overlayClassName="w-max PersonalizeButtonTooltip"
            placement="top"
          >
            <div
              style={{ position: 'absolute', bottom: 0, right: 0 }}
              className="PersonalizeButton rounded-full cursor-pointer"
              onClick={() => setState({ key: 'toggleDynamicParam', value: !toggleDynamicParam })}
            >
              <img src={personalize} alt="personalize" />
            </div>
          </Tooltip>
        )}
      </div>
    </Modal>
  )
}

export default connect(
  (state: any) => ({
    initData: state.campaign.creativeContents.previewData,
  }),
  (dispatch: any) => ({
    setCreativeContentsState: dispatch.campaign.creativeContents.setState,
    clearPreviewData: dispatch.campaign.creativeContents.clearPreviewData,
  })
)(AppPopup)
