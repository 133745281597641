import React, { useState, useEffect } from 'react'
import { Button, Input, Modal, Radio, Select, Tooltip } from 'antd'
import * as yup from 'yup'
import { connect } from 'react-redux'
import { useStoreActions, useStoreState } from 'easy-peasy'
import {
  AI_PARAMS,
  addDynamicParams,
  checkIfDirectChildOfAiCheck,
  findAllNodesOfType,
  findClosestNodeOfType,
  findNearestNodeOfType,
  findNodePosition,
  formatData,
  getAllParentNodes,
  splitValues,
} from '../helpers'
import type { RadioChangeEvent } from 'antd'

import Header from 'Components/CJHeader'
import CreativeContent from 'Containers/CreativeContent'
import Preview from 'Components/CreativeContentsPreview'
import { CurrentType } from 'CustomerJourneyModule'
import ChannelSchemas from 'Constants/ChannelSchemas'
import { AppPushParams } from 'Constants/ChannelSchemas/AppPush.schema'
import personalize from 'Assets/images/personalized.png'

import './index.scss'
import axios from 'Utils/cj-axios'
import DynamicParams from 'Containers/Campaign/MainFlow/Components/Body/Content/Form/DynamicParams'
import useFetchParams from '../useFetchParams'
import { useKeyboardShortcut } from 'Containers/Campaign/MainFlow/Components/Body/Content/useKeyboardShortcut'
import { findNodesByTypeFromEntrance } from './constants'

export const webPushSchema = yup.object().shape({
  title: yup.string(),
  content: yup.string(),
  image: yup.string(),
  utm_source: yup.string().required(),
  utm_medium: yup.string().required(),
  utm_campaign: yup.string().required(),
})

const webPushGenerateSchema = yup.object().shape({
  utm_source: yup.string().required(),
  utm_medium: yup.string().required(),
  utm_campaign: yup.string().required(),
})

const initialValues: AppPushParams = {
  title: '',
  content: '',
  image: '',
  utm_source: '',
  utm_medium: '',
  utm_campaign: '',
  icon: '',
  cta: [{ deep_link: '', option: 'primary', sub_option: '' }],
}

const generateInitialValues = {
  utm_source: '',
  utm_medium: '',
  utm_campaign: '',
}

// const platforms = [
//   { label: 'Android', value: 'android' },
//   { label: 'iOS', value: 'ios' },
// ]

interface Props {
  isPopoverVisible: boolean
  value: any
  initData: any
  current: CurrentType
  isRunning: boolean
  parentNode?: any
  onAddNewNode: (type: string, values: AppPushParams) => void
  onUpdateNode: (values: AppPushParams) => void
  setIsPopoverVisible: React.Dispatch<React.SetStateAction<boolean>>
  setCreativeContentsState: React.Dispatch<React.SetStateAction<any>>
  setCurrent: React.Dispatch<React.SetStateAction<CurrentType>>
}

const AppPushChanel: React.FC<Props> = (props) => {
  const {
    setIsPopoverVisible,
    isPopoverVisible,
    value,
    initData,
    onAddNewNode,
    onUpdateNode,
    setCreativeContentsState,
  } = props
  const [isModalVisible, setIsModalVisible] = useState<boolean>(true)
  const { toggleDynamicParam, content } = useStoreState((state: any) => state.campaignModule)
  const { setState } = useStoreActions((actions: any) => actions.campaignModule)

  const { setPreviewData } = useStoreActions((action: any) => action.campaign.creativeContents)
  const [interactions, setInteractions] = useState<any>([])
  const [segments, setSegments] = useState<any>([])
  const [products, setProducts] = useState<any>([])
  const [productsWithBlogs, setProductsWithBlogs] = useState<any>([])
  const [productDetail, setProductDetail] = useState<any>({})
  const [type, setType] = useState<string>(props?.value?.type || 'dynamic')
  const [dynamicFields, setDynamicFields] = useState<any>([])
  const [isDirectAiCheck, setIsDirectAiCheck] = useState<boolean>(false)

  const fetchProductDetail = async (id: string) => {
    const resp = await axios.get(`/customer-journeys/ai-generate-noti/${id}`)
    setProductDetail(resp.data)
  }

  const fetchBlogDetail = async (id: string) => {
    const resp = await axios.get(`/customer-journeys/ai-generate-recipe/${id}`)
    setProductDetail(resp.data)
  }

  useEffect(() => {
    const fetchInteractions = async () => {
      const resp = await axios.get('/customer-journeys/interactions')
      setInteractions(resp.data)
    }

    const fetchSegments = async () => {
      const resp = await axios.get('/customer-journeys/attributes')
      setSegments(resp.data)
    }

    const fetchProducts = async () => {
      const resp = await axios.get('/customer-journeys/ai-products')
      setProducts(resp.data)
    }

    const fetchProductsWithBlogs = async () => {
      const resp = await axios.get('/customer-journeys/ai-products?group=gen_recipe')
      setProductsWithBlogs(resp.data)
    }

    fetchInteractions()
    fetchProductsWithBlogs()
    fetchSegments()
    fetchProducts()
  }, [])
  const { CJTree } = useStoreState((states) => states.customerJourney)
  useEffect(() => {
    setDynamicFields(
      addDynamicParams(
        ChannelSchemas['ol_app'],
        formatData(
          findNodesByTypeFromEntrance(
            CJTree,
            (props?.current?.nodeUuid as any) ?? props?.parentNode?.childrenNodes?.[0]?.nodeUuid,
            ['waitIn', 'aiCheck', 'condition', 'checkUserAttr', 'entrance']
          )
            ?.map((item: any) => {
              return {
                value: item?.value,
                alias: findNodePosition(CJTree, item?.nodeUuid),
                nodeType: item?.nodeType,
                attributeCode: item?.value?.interaction,
              }
            })
            ?.map((i: any) => {
              if (i?.nodeType === 'waitIn') {
                const typesToMatch = i?.value?.conditions
                  ?.flatMap((condition) => condition.dimension.segments)
                  ?.map((item) => item.type)
                return {
                  ...i,
                  value: interactions?.filter((item: any) => typesToMatch?.includes(item?.code)),
                }
              } else if (i?.nodeType === 'entrance') {
                return {
                  ...i,
                  value: i?.value?.params?.map((item) => ({
                    ...item,
                    parameter_code: `{{${i?.alias}--${item?.parameter_code}}}`,
                  })),
                }
              } else {
                const typesToMatch = i?.value?.segments?.map((item) => item.type)
                return {
                  ...i,
                  value: segments?.filter((item) => typesToMatch?.includes(item?.code)),
                }
              }
            })
        )?.concat(
          [
            'condition',
            'waitIn',
            'wait',
            'split',
            'promotion',
            'coupon',
            'outOfPromotion',
          ]?.includes(props?.parentNode?.nodeType)
            ? []
            : findNodesByTypeFromEntrance(
                CJTree,
                (props?.current?.nodeUuid as any) ||
                  props?.parentNode?.childrenNodes?.[0]?.nodeUuid,
                ['aiCheck']
              )?.flatMap((obj) => {
                return obj?.value?.params?.map((param) => ({
                  ...param,
                  parameter_code: `{{${obj?.alias}--${param?.parameter_code}}}`,
                }))
              })
        )
      )
    )

    setIsDirectAiCheck(checkIfDirectChildOfAiCheck(CJTree, props?.current?.nodeUuid as any))
  }, [
    CJTree,
    props?.current?.nodeUuid,
    interactions,
    segments,
    props?.parentNode?.nodeType,
    isPopoverVisible,
  ])
  useEffect(() => {
    setCreativeContentsState({ key: 'previewData', value: value || initialValues })
    setIsPopoverVisible(false)
    setIsModalVisible(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPopoverVisible])

  const onCloseModal = () => {
    setIsModalVisible(false)
  }

  const onSubmit = async (values: AppPushParams) => {
    const mergedObject = { ...values, type }

    for (const key in content) {
      if (content.hasOwnProperty(key)) {
        mergedObject[key] = content[key]
      }
    }
    onAddNewNode && (await onAddNewNode('ol_app', mergedObject))
    onUpdateNode && (await onUpdateNode(mergedObject))

    setIsModalVisible(false)
  }

  const onChange = (e: RadioChangeEvent) => {
    setType(e.target.value)
    setPreviewData({
      key: 'type',
      value: e.target.value,
    })
  }
  useEffect(() => {
    setCreativeContentsState({
      key: 'previewData',
      value: value || initialValues,
    })
    if (!value) {
      setState({ key: 'content', value: {} })
    } else {
      setState({ key: 'content', value: value })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, isModalVisible])

  useKeyboardShortcut({
    keyCombo: 'Meta+j' || 'Ctrl+j',
    onKeyPressed: () => setState({ key: 'toggleDynamicParam', value: !toggleDynamicParam }),
  })
  return (
    <Modal
      {...props}
      onOk={onCloseModal}
      onCancel={() => setIsModalVisible(false)}
      visible={isModalVisible}
      maskClosable
      title={<Header {...props} />}
      footer={[]}
      width={1100}
      closable={false}
      className="AppPushModal"
    >
      {['aiCheck', 'appInPage', 'webInPage', 'ol_app']?.includes(props?.parentNode?.nodeType) ||
      isDirectAiCheck ? (
        <div>
          <div className="mb-2 font-semibold">Type</div>
          <Radio.Group onChange={onChange} value={type} className="mb-4">
            <Radio value={'dynamic'}>Dynamic</Radio>
            <Radio value={'generate'}>AI generated</Radio>
          </Radio.Group>
        </div>
      ) : null}
      {(type === 'generate' &&
        ['aiCheck', 'appInPage', 'webInPage', 'ol_app']?.includes(props?.parentNode?.nodeType)) ||
      (type === 'generate' && isDirectAiCheck) ? (
        <div className=" mb-4 text-base text-brand_dark bg-brand_light rounded px-2 py-1">
          Đối với AI generated, bạn không thể thay đổi được các thông tin content. Để thay đổi thông
          tin, xin hãy liên hệ team AI
        </div>
      ) : null}
      <div
        id="CampaignContent"
        className={`AppPushComponent relative gap-4 ${props.isRunning ? 'is-running' : ''}`}
      >
        <div className="form-wrapper flex-1" id="ContentForm">
          {((type === 'generate' &&
            ['aiCheck', 'appInPage', 'webInPage', 'ol_app']?.includes(
              props?.parentNode?.nodeType
            )) ||
            isDirectAiCheck) && (
            <div>
              <div className="mb-2 font-semibold">Product item</div>
              <Select
                className="mb-2 w-full rounded"
                placeholder="Select a product"
                onChange={(value: string) => {
                  findClosestNodeOfType(CJTree, 'aiCheck')?.value?.type === 'recommendRecipeBlog'
                    ? fetchBlogDetail(value)
                    : fetchProductDetail(value)
                }}
                showSearch
                onSearch={() => {}}
                filterOption={(input, option) =>
                  ((option?.label as any) ?? '')?.toLowerCase().includes(input.toLowerCase())
                }
                options={(findClosestNodeOfType(CJTree, 'aiCheck')?.value?.type ===
                'recommendRecipeBlog'
                  ? productsWithBlogs
                  : products
                )?.map((item: any) => ({
                  label: item?.product_name,
                  value: item?.product_variant_barcode,
                }))}
              />
              <div className=" font-normal mb-4">
                Chọn từ danh sách sản phẩm để xem được content tương ứng{' '}
              </div>
            </div>
          )}
          {type === 'dynamic' ? (
            <CreativeContent
              onSubmit={onSubmit}
              submitText="Save"
              initValues={value || initialValues}
              validationSchema={webPushSchema}
              fields={dynamicFields}
              onChange={(name: string, value: string) => {
                setPreviewData({
                  key: name,
                  value: value,
                })
                setState({ key: 'content', value: { ...content, [name]: value } })
              }}
            />
          ) : (
            <div className=" flex flex-col gap-3">
              <div>
                <div className=" font-semibold mb-2">Title</div>
                <Input value={productDetail?.headline} disabled className=" px-4 py-2"></Input>
              </div>
              <div>
                <div className=" font-semibold mb-2">Content</div>
                <Input value={productDetail?.body} disabled className=" px-4 py-2"></Input>
              </div>
              <div>
                <div className=" font-semibold mb-2">Content</div>
                <img src={productDetail?.image} width={210} height={92} alt=""></img>
              </div>
              <div>
                <div className=" font-semibold mb-2">Button label</div>
                <Input value={productDetail?.cta} disabled className=" px-4 py-2"></Input>
              </div>
              <div>
                <div className=" font-semibold mb-2">Navigate to</div>
                <Input
                  value={productDetail?.deeplink_value}
                  disabled
                  className=" px-4 py-2"
                ></Input>
              </div>
              <CreativeContent
                onSubmit={onSubmit}
                submitText="Save"
                initValues={value || generateInitialValues}
                validationSchema={webPushGenerateSchema}
                fields={[
                  {
                    name: 'utm_source',
                    type: 'string',
                    label: 'UTM Source',
                    isRequired: true,
                  },
                  {
                    name: 'utm_medium',
                    type: 'string',
                    label: 'UTM Medium',
                    isRequired: true,
                  },
                  {
                    name: 'utm_campaign',
                    type: 'string',
                    label: 'UTM Campaign',
                    isRequired: true,
                  },
                ]}
                onChange={(name: string, value: string) => {
                  setPreviewData({
                    key: name,
                    value: value,
                  })
                }}
              />
            </div>
          )}
        </div>
        <div className="flex-1">
          <Preview isPreview={true} type={'ol_app'} initAiData={productDetail}></Preview>
        </div>
        {toggleDynamicParam && (
          <div
            className=" bg-white border rounded-lg transition-all duration-500"
            style={{ width: '400px' }}
          >
            <DynamicParams
              extraParams={[
                {
                  group_code: 'ai_params',
                  group_name: 'AI Params',
                  parameters: splitValues(
                    dynamicFields?.find((item: any) => item?.type === 'dynamic_content')
                      ?.dynamicParams
                  )?.aiValues?.map((i: any) => {
                    return {
                      parameter_code: i?.parameter_code?.match(/\{\{(.+?)\}\}/)?.[1],
                      format: i?.format?.map((item: any) => {
                        return {
                          ...item,
                          format_key: item?.format_key,
                          format_value: item?.format_value,
                        }
                      }),
                    }
                  }),
                },
                {
                  group_code: 'dimension_params',
                  group_name: 'Dimension Params',
                  parameters: splitValues(
                    dynamicFields?.find((item: any) => item?.type === 'dynamic_content')
                      ?.dynamicParams
                  )?.otherValues?.map((i: any) => {
                    return {
                      parameter_code: i?.parameter_code?.match(/\{\{(.+?)\}\}/)?.[1],
                      format: i?.format?.map((item: any) => {
                        return {
                          ...item,
                          format_key: item?.format_key,
                          format_value: item?.format_value,
                        }
                      }),
                    }
                  }),
                },
                {
                  group_code: 'entrance_params',
                  group_name: 'Entrance Params',
                  parameters: findNodesByTypeFromEntrance(
                    CJTree,
                    (props?.current?.nodeUuid as any) ||
                      props?.parentNode?.childrenNodes?.[0]?.nodeUuid,
                    ['entrance']
                  )?.[0]?.value?.params?.map((item: any) => {
                    return {
                      ...item,
                      parameter_code: `1--${item?.parameter_code}`,
                    }
                  }),
                },
              ]}
            />
          </div>
        )}
        {!toggleDynamicParam && (
          <Tooltip
            title={
              <div className="flex gap-1 flex-col w-max">
                <div className=" font-semibold text-sm">Personalize</div>
                <div className=" text-sm">Add personalized parameters to your message</div>
                <div className=" text-sm text-gray-300">Cmd + J (Mac) / Ctrl + J (Window)</div>
              </div>
            }
            overlayStyle={{ width: 'max-content' }}
            overlayInnerStyle={{ width: 'max-content' }}
            overlayClassName="w-max PersonalizeButtonTooltip"
            placement="top"
          >
            <div
              style={{ position: 'absolute', bottom: 0, right: 0 }}
              className="PersonalizeButton rounded-full cursor-pointer"
              onClick={() => setState({ key: 'toggleDynamicParam', value: !toggleDynamicParam })}
            >
              <img src={personalize} alt="personalize" />
            </div>
          </Tooltip>
        )}
      </div>
    </Modal>
  )
}

interface StateProps {
  campaign: any
}

export default connect(
  (state: StateProps) => ({
    initData: state.campaign.creativeContents.previewData,
  }),
  (dispatch: any) => ({
    setCreativeContentsState: dispatch.campaign.creativeContents.setState,
  })
)(AppPushChanel)
