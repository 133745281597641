import * as yup from 'yup'
export const appStaticPopupSchema = yup.object().shape({
  deeplink: yup.string(),
  imageUrl: yup.string(),
  type: yup.string(),
  utmCampaign: yup.string().required(),
  utmMedium: yup.string().required(),
  utmSource: yup.string().required(),
})

export const appDynamicPopupSchema = yup.object().shape({
  headlineContent: yup.string(),
  headlineColor: yup.string(),
  titleContent: yup.string(),
  titleColor: yup.string(),
  subtitleContent: yup.string(),
  subtitleColor: yup.string(),
  buttonLabel: yup.string(),
  buttonLabelColor: yup.string(),
  buttonBackgroundColor: yup.string(),
  modalLightUp: yup.string(),
  modalLightDown: yup.string(),
  modalRayUp: yup.string(),
  modalRayDown: yup.string(),
  modalSparkle: yup.string(),
  deeplink: yup.string(),
  type: yup.string(),
  utmCampaign: yup.string().required(),
  utmMedium: yup.string().required(),
  utmSource: yup.string().required(),
})

export const appStaticInitialValues = {
  deeplink: '',
  imageUrl: '',
  type: 'MODAL',
  utmCampaign: '',
  utmMedium: '',
  utmSource: '',
}

export const appDynamicInitialValues = {
  headlineContent: '',
  titleContent: '',
  subtitleContent: '',
  buttonLabel: '',
  deeplink: '',
  type: 'ANIMATION',
  utmCampaign: '',
  utmMedium: '',
  utmSource: '',
  headlineColor: 'rgba(58, 95, 171, 1)',
  titleColor: 'rgba(58, 95, 171, 1)',
  subtitleColor: 'rgba(58, 95, 171, 1)',
  buttonLabelColor: 'rgba(255, 255, 255, 1)',
  buttonBackgroundColor: 'rgba(50, 128, 246, 1)',
  modalLightUp: 'linear-gradient(90deg, rgba(224,255,246,1) 0%, RGBA(171, 215, 255, 1) 100%)',
  modalLightDown: 'linear-gradient(90deg, rgba(171,215,255,1) 0%, RGBA(224, 255, 246, 1) 100%)',
  modalRayUp:
    'linear-gradient(90deg, rgba(184,224,254,0) 0%, rgba(184,224,254,1) 47%, RGBA(184, 224, 254, 0) 100%)',
  modalRayDown:
    'linear-gradient(90deg, rgba(217,249,248,0) 0%, rgba(217,249,248,1) 35%, RGBA(217, 249, 248, 0) 100%)',
  modalSparkle: 'rgba(255, 196, 108, 1)',
}

export const appStaticSchemas = [
  { name: 'imageUrl', type: 'image', label: 'Image must be JPG, PNG @3X, 343x456 px' },
  { name: 'deeplink', type: 'dynamic_content', label: 'Navigate to', row: 1 },
  {
    name: 'utmSource',
    type: 'string',
    label: 'UTM Source',
  },
  {
    name: 'utmMedium',
    type: 'string',
    label: 'UTM Medium',
  },
  {
    name: 'utmCampaign',
    type: 'string',
    label: 'UTM Campaign',
  },
]

export const appDynamicSchemas = [
  {
    name: 'headlineContent',
    type: 'dynamic_content',
    label: 'Headline',
    hide: false,
  },
  {
    name: 'headlineColor',
    type: 'color_picker',
    label: 'Headline Color',
    disableGradient: true,
    hide: false,
  },
  {
    name: 'titleContent',
    type: 'dynamic_content',
    label: 'Title',
    hide: false,
  },
  {
    name: 'titleColor',
    type: 'color_picker',
    label: 'Title Color',
    disableGradient: true,
    hide: false,
  },
  {
    name: 'subtitleContent',
    type: 'dynamic_content',
    label: 'Subtitle',
    hide: false,
  },
  {
    name: 'subtitleColor',
    type: 'color_picker',
    label: 'Subtitle Color',
    disableGradient: true,
    hide: false,
  },
  {
    name: 'buttonLabel',
    type: 'dynamic_content',
    label: 'Button Label',
    hide: false,
    row: 1,
  },
  {
    name: 'buttonLabelColor',
    type: 'color_picker',
    label: 'Button Label Color',
    disableGradient: true,
    hide: false,
  },
  {
    name: 'buttonBackgroundColor',
    type: 'color_picker',
    label: 'Button Background Color',
    disableGradient: true,
    hide: false,
  },
  {
    name: 'modalLightUp',
    type: 'color_picker',
    label: 'Modal Light Up',
    disableRgba: true,
    hide: false,
  },
  {
    name: 'modalLightDown',
    type: 'color_picker',
    label: 'Modal Light Down',
    disableRgba: true,
    hide: false,
  },
  {
    name: 'modalRayUp',
    type: 'color_picker',
    label: 'Modal Ray Up',
    disableRgba: true,
    hide: false,
  },
  {
    name: 'modalRayDown',
    type: 'color_picker',
    label: 'Modal Ray Down',
    disableRgba: true,
    hide: false,
  },
  {
    name: 'modalSparkle',
    type: 'color_picker',
    label: 'Modal Sparkle',
    disableGradient: true,
    hide: false,
  },
  {
    name: 'deeplink',
    type: 'dynamic_content',
    label: 'Navigate to',
    hide: false,
    row: 1,
  },
  //   {
  //     name: 'type',
  //     type: 'string',
  //     label: 'Type',
  //   },
  {
    name: 'utmCampaign',
    type: 'string',
    label: 'UTM Campaign',
    hide: false,
  },
  {
    name: 'utmMedium',
    type: 'string',
    label: 'UTM Medium',
    hide: false,
  },
  {
    name: 'utmSource',
    type: 'string',
    label: 'UTM Source',
    hide: false,
  },
]

export const contentFields = [
  'headlineContent',
  'titleContent',
  'subtitleContent',
  'buttonLabel',
  'deeplink',
]

export const styleFields = [
  'headlineColor',
  'titleColor',
  'subtitleColor',
  'buttonLabelColor',
  'buttonBackgroundColor',
  'modalLightUp',
  'modalLightDown',
  'modalRayUp',
  'modalRayDown',
  'modalSparkle',
]

export const DEFAULT_FIELDS = [
  {
    name: 'headlineContent',
    type: 'dynamic_content',
    label: 'Headline',
    hide: false,
  },
  {
    name: 'headlineColor',
    type: 'color_picker',
    label: 'Headline Color',
    disableGradient: true,
    hide: true,
  },
  {
    name: 'titleContent',
    type: 'dynamic_content',
    label: 'Title',
    hide: false,
  },
  {
    name: 'titleColor',
    type: 'color_picker',
    label: 'Title Color',
    disableGradient: true,
    hide: true,
  },
  {
    name: 'subtitleContent',
    type: 'dynamic_content',
    label: 'Subtitle',
    hide: false,
  },
  {
    name: 'subtitleColor',
    type: 'color_picker',
    label: 'Subtitle Color',
    disableGradient: true,
    hide: true,
  },
  {
    name: 'buttonLabel',
    type: 'dynamic_content',
    label: 'Button Label',
    hide: false,
    row: 1,
  },
  {
    name: 'buttonLabelColor',
    type: 'color_picker',
    label: 'Button Label Color',
    disableGradient: true,
    hide: true,
  },
  {
    name: 'buttonBackgroundColor',
    type: 'color_picker',
    label: 'Button Background Color',
    disableGradient: true,
    hide: true,
  },
  {
    name: 'modalLightUp',
    type: 'color_picker',
    label: 'Modal Light Up',
    disableRgba: true,
    hide: true,
  },
  {
    name: 'modalLightDown',
    type: 'color_picker',
    label: 'Modal Light Down',
    disableRgba: true,
    hide: true,
  },
  {
    name: 'modalRayUp',
    type: 'color_picker',
    label: 'Modal Ray Up',
    disableRgba: true,
    hide: true,
  },
  {
    name: 'modalRayDown',
    type: 'color_picker',
    label: 'Modal Ray Down',
    disableRgba: true,
    hide: true,
  },
  {
    name: 'modalSparkle',
    type: 'color_picker',
    label: 'Modal Sparkle',
    disableGradient: true,
    hide: true,
  },
  {
    name: 'deeplink',
    type: 'dynamic_content',
    label: 'Navigate to',
    hide: false,
    row: 1,
  },
  {
    name: 'utmCampaign',
    type: 'string',
    label: 'UTM Campaign',
    hide: true,
  },
  {
    name: 'utmMedium',
    type: 'string',
    label: 'UTM Medium',
    hide: true,
  },
  {
    name: 'utmSource',
    type: 'string',
    label: 'UTM Source',
    hide: true,
  },
]

export const settingFields = ['utmCampaign', 'utmMedium', 'utmSource']

function updateHideProperty(schemas, fields) {
  // Convert fields array to a Set for faster lookup
  const fieldSet = new Set(fields)

  // Iterate over the schemas array and update the hide property
  return schemas?.map((item) => {
    // Check if the item's name is in the fieldSet
    if (!fieldSet?.has(item.name)) {
      // Set hide to true if name is not in the contentFields array
      return { ...item, hide: true }
    }
    // Return the item unchanged if the name is in the contentFields array
    return { ...item, hide: false }
  })
}

export const TABS = [
  {
    label: 'Content',
    key: 'content',
    fields: updateHideProperty(appDynamicSchemas, contentFields),
  },
  {
    label: 'Styles',
    key: 'styles',
    fields: updateHideProperty(appDynamicSchemas, styleFields),
  },
  {
    label: 'Settings',
    key: 'settings',
    fields: updateHideProperty(appDynamicSchemas, settingFields),
  },
]

export function getSelectedInitialValue(initialValues, fields) {
  const result = {}
  fields?.forEach((field) => {
    if (initialValues?.hasOwnProperty(field)) {
      result[field] = initialValues[field]
    }
  })
  return result
}

export function filterSchemasByFields(schemas, fields) {
  return schemas?.filter((schema) => fields?.includes(schema?.name))
}

export function getSelectedSchemaFields(schema, fields) {
  const shape = schema.fields
  const selectedShape = {}

  fields.forEach((field) => {
    if (shape[field]) {
      selectedShape[field] = shape[field]
    }
  })

  return yup.object().shape(selectedShape)
}

export function getStaticInitialValues(original, staticInitialValues) {
  const result = {}
  for (const key in staticInitialValues) {
    if (original.hasOwnProperty(key)) {
      result[key] = original[key]
    }
  }
  return result
}

export function mergeObjects(obj1, obj2) {
  const merged = { ...obj2, ...obj1 }
  return merged
}
